<template>
  <div
    class="grid grid-cols-10 w-full gap-3 items-center"
  >
    <DgSelect
      :value="county"
      @selected="methodUpdateCounty"
      placeholder="請選擇縣市"
      :list="countyList"
      class="col-span-2"
    />
    <DgSelect
      :value="district"
      @selected="methodUpdateDistrict"
      placeholder="請選擇區域"
      :list="districtList"
      class="col-span-2"
    />
    <DgInput
      :value="zipCode"
      class="col-span-1"
      :disabled="true"
    />
    <DgInput
      :value="address"
      @input="methodUpdateAddress"
      placeholder="請輸入詳細地址"
      class="col-span-5"
    />
  </div>
</template>

<script>
import { isEqual } from '@/lib/lodash';
import DgSelect from '@/components/dgForm/DgSelect.vue';
import DgInput from '@/components/dgForm/DgInput.vue';
import address from '@/utils/address';

export default {
  name: 'DgAddress',
  components: {
    DgSelect,
    DgInput,
  },
  data() {
    return {
      county: '',
      district: '',
      address: '',
      addressInfo: address,
    };
  },
  computed: {
    countyList() {
      return this.addressInfo?.counties?.map((label, value) => ({
        label,
        value,
      })) ?? [];
    },
    districtList() {
      const selectCounty = this.county?.value ?? '';
      if (selectCounty === '') return [];
      return this.addressInfo?.districts[selectCounty][0]?.map((label, value) => ({
        label,
        value,
      })) ?? [];
    },
    zipCode() {
      const selectCounty = this.county?.value ?? '';
      const selectDistrict = this.district?.value ?? '';
      if (selectCounty === '' || selectDistrict === '') return '';
      return this.addressInfo?.districts[selectCounty][1][selectDistrict];
    },
  },
  watch: {
    county(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.district = '';
      }
    },
  },
  methods: {
    methodUpdateCounty(v) {
      this.county = v;
      this.methodUpdate();
    },
    methodUpdateDistrict(v) {
      this.district = v;
      this.methodUpdate();
    },
    methodUpdateAddress(v) {
      this.address = v;
      this.methodUpdate();
    },
    methodUpdate() {
      this.$emit('input', {
        county: this.county.label,
        district: this.district.label,
        zipCode: this.zipCode,
        address: this.address,
      });
    },
  },
};
</script>
