<script>
import { isEqual } from '@/lib/lodash';
import DgLabel from '@/components/dgForm/DgLabel.vue';
import validator from '@/lib/validator';

export default {
  render(h) {
    return h(
      'div',
      {
        class: 'relative',
      },
      [
        h(
          'DgLabel',
          {
            attrs: {
              label: this.label,
              required: this.required,
              description: this.descriptionText,
              limitText: this.limitText,
            },
          },
          this.$slots.default,
        ),
        h(
          this.formType,
          {
            props: {
              ...this.$props,
              errorMsg: this.errorMsg,
            },
            attrs: {
              ...this.$attrs,
            },
            on: {
              [this.formEventName]: this.methodOnInput,
              finalReplace: this.methodOnFinalReplace,
              clear: this.methodOnClear,
            },
          },
        ),
      ],
    );
  },
  name: 'FormItem',
  inheritAttrs: false,
  props: {
    formType: {
      type: Object,
    },
    label: {
      type: String,
    },
    value: {
      type: [File, String, Array, Object, Number],
    },
    uniKey: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    description: {
      type: String,
    },
    lazyValid: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    formEventName: {
      type: String,
      default: 'input',
    },
    // file
    accept: {
      type: String,
    },
    maxSize: {
      type: [String, Number],
    },
    // input
    minlength: {
      type: Number,
      default: 0,
    },
    maxlength: {
      type: Number,
    },
    callback: {
      type: Function,
    },
  },
  components: {
    DgLabel,
  },
  data() {
    return {
      errorMsg: '',
      validator: () => null,
    };
  },
  computed: {
    validRule() {
      return {
        [this.uniKey]: this.rules.map((func) => (_, v, cb) => func(v, cb)),
      };
    },
    validValue() {
      return {
        [this.uniKey]: this.value,
      };
    },
    descriptionText() {
      const settingsText = [this.acceptText, this.maxSizeText].filter((t) => t).join('，');
      const result = `(${this.description || ''}${settingsText})`;
      return result?.length > 2 ? result : '';
    },
    acceptText() {
      return this.accept ? `限制格式 ${this.accept.replace(/[\\.\\*\\/]/g, '').split(',').join(' / ')}` : '';
    },
    maxSizeText() {
      return this.maxSize ? `大小 ${this.maxSize} 以下` : '';
    },
    limitText() {
      if (this.maxlength > 0) {
        return `${this.minlength}/${this.maxlength}`;
      }
      return '';
    },
  },
  watch: {
    validRule: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.methodInitValidator();
        }
      },
      immediate: true,
    },
  },
  methods: {
    methodOnClear() {
      this.$emit('input', '');
      this.errorMsg = '';
    },
    methodOnInput(v) {
      this.$emit('input', v);
      if (this.callback) this.callback(v);

      this.$nextTick(() => {
        if (this.lazyValid) {
          if (this.errorMsg) this.methodValid();
        } else {
          this.methodValid();
        }
      });
    },
    methodOnFinalReplace(v) {
      this.$emit('finalReplace', v);
    },
    methodInitValidator() {
      this.validator = validator(this.validRule);
    },
    methodValid() {
      const result = this.validator(this.validValue);
      this.errorMsg = result ? result[this.uniKey] : '';
      return result;
    },
  },
};
</script>
