<template>
  <div
    class="flex flex-row whitespace-nowrap items-start relative"
    :class="isFormLayout ? 'mt-3.5' : ''"
  >
    <p
      v-if="!isFormLayout"
      v-show="label"
      class="text-sm text-ash-800 mr-5 py-0.5"
    >{{label}}</p>
    <div
      class="flex"
      :class="isFormLayout ? 'flex-wrap' : 'overflow-auto pb-5'"
    >
      <span
        v-for="(item, idx) in list"
        :key="`tag-item-${item.label}-${idx}`"
        @click="methodSelect(item)"
        class="gtm-filter-tag"
        :class="[
        selectable? 'cursor-pointer' : '',
          methodGetSpanClass(),
          methodGetSpanActiveClass(item),
        ]"
      >
        <DgIcon
          v-if="item.icon"
          :size="isCard ? 40: 18"
          :name="item.icon"
          :color="methodItemIsSelected(item)
            ? (layoutType === 'formCard' ? 'primary' : 'white')
            : 'ash-600'"
          :class="layoutType === 'formCard'? 'mb-2.5' : 'mr-2'"
        />
        {{item.label}}
      </span>
    </div>
    <div
      v-if="rightSlotSetting"
    >
      <p
        v-if="rightSlotSetting.componentType==='button'"
        class="text-sm text-primary cursor-pointer ml-2"
        @click="methofOnPress"
      >{{rightSlotSetting.label}}</p>
    </div>
    <span
      v-if="errorMsg"
      class="text-xs text-primary absolute left-1 top-full transform translate-y-0.5 opacity-90"
    >{{errorMsg}}</span>
  </div>
</template>

<script>
import { isEqual, cloneDeep } from '@/lib/lodash';
import DgIcon from '@/components/base/DgIcon.vue';

export default {
  name: 'TagsRow',
  props: {
    layoutType: {
      type: String,
      default: 'default',
      validator(v) {
        return ['default', 'form', 'md', 'formCard'].indexOf(v) !== -1;
      },
    },
    label: {
      type: String,
    },
    list: {
      type: Array,
    },
    value: {
      type: [Array, Object],
    },
    selectable: {
      type: Boolean,
    },
    multi: {
      type: Boolean,
      default: true,
    },
    all: {
      type: Boolean,
    },
    rightSlotSetting: {
      type: Object,
    },
    errorMsg: {
      type: String,
      default: '',
    },
  },
  components: {
    DgIcon,
  },
  data() {
    return {
    };
  },
  computed: {
    isFormLayout() {
      return /form/.test(this.layoutType);
    },
    isCard() {
      return /card/i.test(this.layoutType);
    },
  },
  methods: {
    methodGetSpanClass() {
      if (this.layoutType === 'md') return 'ml-3 flex rounded-full px-3 py-2 text-base';
      if (this.layoutType === 'form') return 'ml-3 flex rounded-full border mb-3 px-2 py-0.5 text-sm';
      if (this.layoutType === 'default') return 'ml-3 flex rounded-full px-2 py-0.5 text-sm';
      if (this.layoutType === 'formCard') return 'ml-4 flex flex-col justify-center items-center text-sm font-bold p-4 pb-2';
      return '';
    },
    methodGetSpanActiveClass(item) {
      if (this.layoutType === 'md') {
        if (this.methodItemIsSelected(item)) {
          return 'bg-primary text-white';
        }
        return 'bg-white text-ash-600';
      }
      if (this.layoutType === 'form') {
        if (this.methodItemIsSelected(item)) {
          return 'border-transparent bg-primary text-white';
        }
        return 'border-ash-300 text-ash-300';
      }
      if (this.layoutType === 'default') {
        if (this.methodItemIsSelected(item)) {
          return 'bg-primary text-white';
        }
        return 'text-ash-300 ';
      }
      if (this.layoutType === 'formCard') {
        if (!this.selectable) return 'text-primary border border-primary rounded-md';
        if (this.methodItemIsSelected(item)) {
          return 'text-primary bg-ash-50 shadow-md';
        }
        return 'text-ash-300 bg-white shadow-md';
      }
      return '';
    },
    methodItemIsSelected(item) {
      if (this.multi) {
        return !!this.value.find((i) => isEqual(i, item));
      }
      return isEqual(this.value, item);
    },
    methodSelect(item) {
      if (this.selectable !== undefined && !this.selectable) return;
      if (this.multi) {
        const itemInSelectedIdx = this.value ? this.value.findIndex((i) => isEqual(i, item)) : -1;
        if (itemInSelectedIdx === -1) {
          if (this.all) {
            const onlyAll = item.value === '';
            if (onlyAll) {
              this.$emit('selected',
                [
                  this.list[0],
                ]);
            } else {
              const list = cloneDeep(this.value);
              const removeAllTagIdx = list.findIndex(({ value }) => value === '');
              if (removeAllTagIdx !== -1) list.splice(removeAllTagIdx, 1);
              this.$emit('selected',
                [
                  ...list,
                  item,
                ]);
            }
          } else {
            this.$emit('selected',
              [
                ...this.value,
                item,
              ]);
          }
        } else {
          this.$emit('selected',
            [
              ...this.value.slice(0, itemInSelectedIdx),
              ...this.value.slice(itemInSelectedIdx + 1),
            ]);
        }
      } else {
        this.$emit('selected', item);
        // [item]);
      }
    },
    methofOnPress() {
      if (this.rightSlot?.clicked) {
        if (this.rightSlot?.clicked?.type === 'route') {
          this.$router.push(this.rightSlot?.clicked?.route);
        }
      }
    },
  },
};
</script>
