<template>
  <div
    class="relative"
  >
    <input
      :type="inputType"
      ref="dgInput"
      class="w-full rounded-md focus:border-primary focus:ring-transparent
      placeholder-ash-200 text-ash-800 "
      :class="[
        errorMsg ? 'border-primary border-opacity-90' : 'border-ash-100',
        value && !disabled ? 'pr-12' : '',
        icon ? 'pl-12' : '',
      ]"
      :placeholder="placeholder"
      :minlength="minlength"
      :maxlength="maxlength"
      :value="value"
      :disabled="disabled"
      @input="methodUpdate"
    />
    <div
      v-show="value && !disabled"
      class="flex h-full absolute top-0 right-0 px-3.5 cursor-pointer"
      @click="methodClear"
    >
      <DgIcon
        size="17"
        name="times-circle"
        color="ash-200"
      />
    </div>
    <span
      v-if="errorMsg"
      class="text-xs text-primary absolute left-1 top-full transform translate-y-0.5 opacity-90"
    >{{errorMsg}}</span>
  </div>
</template>

<script>
import DgIcon from '@/components/base/DgIcon.vue';

export default {
  inheritAttrs: false,
  name: 'DgEmail',
  props: {
    value: {
      type: String,
    },
    inputType: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
    },
    errorMsg: {
      type: String,
      default: '',
    },
    minlength: {
      type: Number,
      default: 0,
    },
    maxlength: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    },
  },
  components: {
    DgIcon,
  },
  data() {
    return {
    };
  },
  methods: {
    methodClear() {
      this.$emit('clear');
    },
    methodUpdate(v) {
      const result = v.target.value;
      this.$refs.dgInput.value = result;
      this.$emit('input', result);
    },
  },
};
</script>
