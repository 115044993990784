<template>
  <div
    class=""
  >
    <DgImg
      v-if="avatar"
      :src="avatar"
      :ratio="1"
      class="rounded-full"
      :style="{
        width: `${size}px`,
        height: `${size}px`,
      }"
    />
    <DgIcon
      v-else
      :size="size"
      name="user-circle"
      color="ash-600"
    />
  </div>
</template>

<script>
import DgImg from '@/components/base/DgImg.vue';
import DgIcon from '@/components/base/DgIcon.vue';

export default {
  name: 'Avatar',
  props: {
    avatar: {
      type: String,
    },
    size: {
      type: [Number, String],
    },
  },
  components: {
    DgImg,
    DgIcon,
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>
