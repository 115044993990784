<template>
  <div
    class="relative"
  >
    <div
      class="flex items-center"
    >
      <DgCheckbox
        v-for="(item, idx) in list"
        :key="`checkbox-item-${item.label}-${idx}`"
        class="mr-6"
        :label="item.label"
        :value="methodGetValue(item)"
        @input="methodUpdate(item)"
      />
    </div>
    <span
      v-if="errorMsg"
      class="text-xs text-primary absolute left-1 top-full transform translate-y-0.5 opacity-90"
    >{{errorMsg}}</span>
  </div>
</template>

<script>
import { isEqual, cloneDeep } from '@/lib/lodash';
import DgCheckbox from '@/components/base/DgCheckbox.vue';

export default {
  name: 'DgCheckboxGroup',
  props: {
    value: {
      type: Array,
    },
    list: {
      type: Array,
    },
    errorMsg: {
      type: String,
      default: '',
    },
  },
  components: {
    DgCheckbox,
  },
  data() {
    return {
      currentValue: [],
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.currentValue = newVal;
      }
    },
  },
  methods: {
    methodGetValue(v) {
      const idx = this.currentValue.findIndex((e) => isEqual(e, v));
      return idx !== -1;
    },
    methodUpdate(v) {
      const result = cloneDeep(this.currentValue);
      const idx = result.findIndex((e) => isEqual(e, v));
      if (idx !== -1) {
        result.splice(idx, 1);
      } else {
        result.push(v);
      }
      this.$emit('input', result);
    },
  },

};
</script>
