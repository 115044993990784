<template>
  <div
    class="flex justify-between items-end "
    :class="label || limitText ? 'mb-1.5' : '-mt-4'"
  >
    <p
      v-if="label"
      class="text-base text-ash-800"
    >
      {{label}}<span
        v-if="required"
        class="text-base text-primary"
      >*</span>
      <span
        v-if="description"
        class="text-sm text-ash-300 ml-2"
      >{{description}}</span>
    </p>
    <p
      v-if="limitText"
      class="text-xs text-ash-600"
    >{{limitText}}</p>
  </div>
</template>

<script>
export default {
  name: 'DgLabel',
  props: {
    label: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    description: {
      type: String,
    },
    limitText: {
      type: String,
    },
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>

</style>
