<template>
  <label
    class="flex items-center text-base text-ash-800 cursor-pointer"
  >
    <input
      type="checkbox"
      class="w-6 h-6 rounded-md border-ash-300 text-primary cursor-pointer mr-4
      focus:ring-transparent"
      :checked="value"
      @input="methodUpdate"
    >
    {{label}}
  </label>
</template>

<script>
export default {
  name: 'DgCheckbox',
  props: {
    value: {
      type: Boolean,
    },
    label: {
      type: String,
    },
  },
  data() {
    return {

    };
  },
  methods: {
    methodUpdate(e) {
      this.$emit('input', e.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
