<template>
  <div
    class="relative"
  >
    <input
      :type="showPassword ? 'text' : inputType"
      ref="dgInput"
      autocomplete="off"
      :readonly="!isMobile"
      class="w-full rounded-md focus:border-primary focus:ring-transparent
      placeholder-ash-200 text-ash-800 pr-"
      :class="[
        errorMsg ? 'border-primary border-opacity-90' : 'border-ash-100',
        inputPaddingRight,
        icon ? 'pl-12' : '',
      ]"
      :placeholder="placeholder"
      :max="max"
      :minlength="minlength"
      :maxlength="maxlength"
      :value="value"
      :disabled="disabled"
      @input="methodUpdate"
      @focus="methodFocus"
      @blur="methodBlur"
    />
    <div
      v-if="icon"
      class="flex items-center h-full absolute top-0 left-0 px-3.5"
    >
      <DgIcon
        size="23"
        :name="icon"
      />
    </div>
    <div
      v-show="isPassword"
      class="flex h-full absolute top-0 right-0 px-3.5 cursor-pointer"
      @click="methodTogglePassword"
    >
      <DgIcon
        size="20"
        name="eye"
        color="ash-200"
      />
    </div>
    <div
      v-show="value && !disabled"
      class="flex h-full absolute top-0 px-3.5 cursor-pointer"
      :class="inputType === 'password' ? 'right-9' : 'right-0'"
      @click="methodClear"
    >
      <DgIcon
        size="17"
        name="times-circle"
        color="ash-200"
      />
    </div>
    <span
      v-if="errorMsg"
      class="text-xs text-primary absolute left-1 top-full transform translate-y-0.5 opacity-90"
    >{{errorMsg}}</span>
  </div>
</template>

<script>
import DgIcon from '@/components/base/DgIcon.vue';

export default {
  inheritAttrs: false,
  inject: ['device'],
  name: 'DgInput',
  props: {
    value: {
      type: String,
    },
    inputType: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
    },
    icon: {
      type: String,
    },
    errorMsg: {
      type: String,
      default: '',
    },
    minlength: {
      type: Number,
      default: 0,
    },
    maxlength: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    },
    layout: {
      type: String,
      default: 'normal',
    },
    max: {
      type: String,
    },
  },
  components: {
    DgIcon,
  },
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    isMobile() {
      return this.device.isMobile;
    },
    isPassword() {
      return this.inputType === 'password';
    },
    inputClass() {
      if (this.layout === 'search') return 'bg-ash-50 placeholder-ash-300 text-ash-600 border-0';
      return 'rounded-lg focus:border-primary placeholder-ash-200 text-ash-800';
    },
    inputPaddingRight() {
      let base = 0;
      if (this.isPassword) base = 8;
      return this.value && !this.disabled ? `pr-${12 + base}` : '';
    },
  },
  methods: {
    methodClear() {
      this.$emit('clear');
    },
    methodUpdate(v) {
      const value = (v?.target?.value ?? '')?.trim();
      this.$emit('input', value);
    },
    methodFocus() {
      this.$emit('focus');
      if (this.isMobile) return;
      this.$refs.dgInput.removeAttribute('readonly');
    },
    methodBlur() {
      this.$emit('blur');
    },
    methodTogglePassword() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>
