<template>
  <div
    class="grid grid-cols-2 gap-8 relative"
  >
    <FormItem
      :ref="`form-${item.uniKey}`"
      v-for="(item, idx) in settings"
      v-show="!item.hide"
      :key="`-${item}-${idx}`"
      :class="[`col-span-${item.col || 2}`, {'flex flex-col items-center' : alignCenter}]"
      v-bind="item"
      :value="value[item.uniKey]"
      :formType="components[item.componentType]"
      @input="(v)=>methodUpdate(item.uniKey,v)"
      @finalReplace="(v)=>methodUpdateFinal(item.uniKey,v)"
    />
  </div>
</template>

<script>
import { isEqual, cloneDeep } from '@/lib/lodash';
import FormItem from '@/components/dgForm/FormItem.vue';
import DgInput from '@/components/dgForm/DgInput.vue';
import DgEmail from '@/components/dgForm/DgEmail.vue';
import DgPhone from '@/components/dgForm/DgPhone.vue';
import DgTextarea from '@/components/dgForm/DgTextarea.vue';
import TagsRow from '@/components/filter/TagsRow.vue';
import DgRadio from '@/components/dgForm/DgRadio.vue';
import DgSelect from '@/components/dgForm/DgSelect.vue';
import DgEditor from '@/components/dgForm/DgEditor.vue';
import DgCheckboxGroup from '@/components/dgForm/DgCheckboxGroup.vue';
import DgSortable from '@/components/dgForm/DgSortable.vue';
import DgItemSelect from '@/components/dgForm/DgItemSelect.vue';
import DgSlider from '@/components/dgForm/DgSlider.vue';
import DgAddress from '@/components/dgForm/DgAddress.vue';

export default {
  name: 'DgForm',
  components: {
    FormItem,
  },
  props: {
    settings: {
      type: Array,
    },
    alignCenter: {
      type: Boolean,
    },
  },
  data() {
    return {
      init: false,
      components: {
        text: DgInput,
        email: DgEmail,
        phone: DgPhone,
        textarea: DgTextarea,
        tags: TagsRow,
        radio: DgRadio,
        select: DgSelect,
        editor: DgEditor,
        checkboxGroup: DgCheckboxGroup,
        sortable: DgSortable,
        itemSelect: DgItemSelect,
        slider: DgSlider,
        address: DgAddress,
      },
      value: {},
      defaultValue: {},
      defaultTypeValue: {
        text: '',
        email: '',
        phone: '',
        textarea: '',
        tags: [],
        radio: '',
        select: '',
        editor: '',
        checkboxGroup: [],
        sortable: [],
        itemSelect: '',
        slider: 0,
        address: '',
      },
    };
  },
  computed: {
    descriptor() {
      return this.settings.reduce((acc, cur) => {
        acc[cur.uniKey] = cur.rules || [];
        return acc;
      }, {});
    },
  },
  watch: {
    settings: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal) && newVal && !this.init) {
          this.init = true;
          this.methodInitValue();
        }
      },
      immediate: true,
    },
  },
  methods: {
    methodInitValue() {
      const result = this.settings.reduce((acc, cur) => {
        acc[cur.uniKey] = cur.defaultValue
        || this.defaultValue[cur.uniKey]
        || this.defaultTypeValue[cur.componentType];
        return acc;
      }, {});
      this.value = result;
    },
    methodUpdate(key, value) {
      const result = cloneDeep(this.value);
      result[key] = value;
      this.value = result;
    },
    methodUpdateFinal(key, value) {
      const result = cloneDeep(this.value);
      result[`${key}_final`] = value;
      this.value = result;
    },
    methodValid() {
      const res = Object.keys(this.$refs).reduce((acc, key) => {
        let result = null;
        if (/^form-/.test(key)) {
          result = !!this.$refs[key][0]?.methodValid();
        }
        return acc && !result;
      }, true) || false;
      return res;
    },
    methodGetContent() {
      const valid = this.methodValid();
      return valid ? this.value : valid;
    },
    methodUpdateValue(value) {
      this.defaultValue = value;
      this.value = {
        ...this.value,
        ...value,
      };
    },
  },
};
</script>
