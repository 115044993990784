<template>
  <div
    class="relative"
  >
    <div
      class=""
    >
      <!-- checkbox -->
      <div
        class="flex items-center"
      >
        <DgCheckbox
          v-for="(item, idx) in checkboxList"
          :key="`sortable-checkbox-item-${item.label}-${idx}`"
          class="mr-6"
          :label="item.label"
          :value="methodGetValue(item)"
          @input="methodUpdateCheckbox(item),methodUpdateSortable(item)"
        />
      </div>

      <!-- drag選擇 @start, @end -->
      <draggable
        class="mt-6 flex flex-col"
        tag="div"
        v-bind="dragOptions"
        :value="sortableList"
        @input="methodSortabled"
        :move="onMove"
      >
        <transition-group
          type="transition"
          name="flip-list"
        >
          <div
            v-for="(item, idx) in sortableList"
            :key="`sortable-item-${item}-${idx}`"
            class="py-4 flex items-center rounded-md border border-ash-600 border-opacity-20
            mb-3 bg-white"
            :class="item.fixed ? 'bg-ash-50' : ''"
          >
            <div
              v-if="!item.fixed"
              class="sortable-handle px-3 mr-1 cursor-move"
            >
              <DgIcon
                size="18"
                name="equals"
                color="ash-600"
                class=""
              />
            </div>
            <p
              class="text-base text-ash-600 pl-3"
              :class="item.fixed ? '' : 'font-bold'"
            >{{item.label}}</p>
          </div>
        </transition-group>
      </draggable>
    </div>
    <span
      v-if="errorMsg"
      class="text-xs text-primary absolute left-1 top-full transform translate-y-0.5 opacity-90"
    >{{errorMsg}}</span>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { isEqual, cloneDeep } from '@/lib/lodash';
import DgCheckbox from '@/components/base/DgCheckbox.vue';
import DgIcon from '@/components/base/DgIcon.vue';

export default {
  name: 'DgSortable',
  props: {
    value: {
      type: Array,
    },
    list: {
      type: Array,
    },
    errorMsg: {
      type: String,
      default: '',
    },
    uniKey: {
      type: String,
    },
  },
  components: {
    draggable,
    DgCheckbox,
    DgIcon,
  },
  data() {
    return {
      checkboxValue: [],
      sortableList: [],
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 150,
        group: this.uniKey,
        handle: '.sortable-handle',
      };
    },
    checkboxList() {
      return this.list.filter(({ fixed }) => !fixed);
    },
    unsortableList() {
      return this.list.filter(({ fixed }) => fixed);
    },
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.checkboxValue = newVal;
          this.methodSetUnsortableList(newVal);
        }
      },
      immediate: true,
    },
    unsortableList: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.methodSetUnsortableList();
        }
      },
    },
  },
  mounted() {
  },
  methods: {
    onMove(res) {
      const { relatedContext, draggedContext } = res;
      if (!relatedContext?.element) return false;
      if (!draggedContext?.element) return false;
      if (relatedContext?.element?.fixed) return false;
      if (draggedContext?.element?.fixed) return false;
      return true;
    },
    methodGetValue(v) {
      const idx = this.checkboxValue.findIndex((e) => isEqual(e, v));
      return idx !== -1;
    },
    methodUpdateCheckbox(v) {
      const result = cloneDeep(this.checkboxValue);
      const idx = result.findIndex((e) => isEqual(e, v));
      if (idx !== -1) {
        result.splice(idx, 1);
      } else {
        result.push(v);
      }
      this.checkboxValue = result;
    },
    methodUpdateSortable(v) {
      const result = cloneDeep(this.sortableList);
      const idx = result.findIndex((e) => isEqual(e, v));
      if (idx !== -1) {
        result.splice(idx, 1);
      } else {
        result.push(v);
      }
      this.methodSetUnsortableList(result);
    },
    methodSortabled(v) {
      this.methodSetUnsortableList(v);
    },
    methodSetUnsortableList(defaultList) {
      const list = cloneDeep(defaultList || this.sortableList);
      const result = list.filter((e) => !e.fixed);
      this.unsortableList.forEach((item) => {
        const insertIdx = item.positionIdx || 0;
        result.splice(insertIdx, 0, item);
      });
      this.sortableList = result.filter((e) => e);
      this.$emit('input', this.sortableList);
    },
  },

};
</script>
