<template>
  <div class="relative pb-1">
    <div
      class="mt-1 relative"
    >
      <button
        ref="dgSelectButton"
        type="button"
        class="bg-white relative w-full border rounded-md px-4 py-2 pr-6
        text-left focus:ring-transparent"
        :class="[
          errorMsg ? 'border-primary border-opacity-90' : 'border-ash-100',
        ]"
        @click="methodToggleShow()"
      >

        <input
          v-if="filter"
          class="border-0 p-0 focus:ring-0 focus:border-0 placeholder-ash-200 w-full text-ash-800"
          type="text"
          :placeholder="placeholder || ''"
          v-model="filterText"
        >
        <div
          v-else
          class="overflow-x-auto w-full"
        >
          <div
            v-if="multi ? showMulitValue : value"
          >
            <span
              v-if="multi"
              class="whitespace-nowrap"
            >{{showMulitValue}}</span>
            <span
              v-else
              class="text-ash-800 truncate">{{value.label}}</span>
          </div>
          <span
            v-else
            class="text-ash-200 truncate">{{placeholder || ''}}</span>
        </div>
        <span class="absolute inset-y-0 right-0 flex items-center pointer-events-none px-4">
          <DgIcon size="7" name="chevron-down" color="ash-600" class="" />
        </span>
      </button>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ul
          v-show="show"
          class="absolute z-20 mt-1 w-full bg-white shadow-md max-h-60 rounded-md py-3.5
          text-base ring-transparent overflow-auto focus:outline-none"
          tabindex="-1"
        >
          <li
            v-for="(item, idx) in listShow"
            :key="`dgseleft-item-${item}-${idx}`"
            :class="[
              'cursor-pointer select-none relative px-4 py-2 flex items-center',
              activeIndex === idx || methodItemIsSelected(item)
              ? 'text-white bg-primary' :
              'text-ash-600',
            ]"
            @click="methodUpdate(item, idx)"
            @mouseenter="activeIndex = idx"
            @mouseleave="activeIndex = null"
          >
            <Avatar
              v-if="item.avatar!==undefined"
              :avatar="item.avatar"
              size="32"
              class="mr-2"
            />
            <span
              class="truncate"
            >
              {{item.label}}
            </span>
          </li>
        </ul>
      </transition>
    </div>
    <span
      v-if="errorMsg"
      class="text-xs text-primary absolute left-1 top-full transform translate-y-0.5 opacity-90"
      >{{ errorMsg }}</span
    >
  </div>
</template>

<script>
import { isEqual } from '@/lib/lodash';
import DgIcon from '@/components/base/DgIcon.vue';
import Avatar from '@/components/app/header/Avatar.vue';

export default {
  name: 'DgSelect',
  props: {
    value: {
      type: [String, Object, Array],
    },
    placeholder: {
      type: String,
      default: '請選擇',
    },
    list: {
      type: Array,
    },
    errorMsg: {
      type: String,
      default: '',
    },
    filter: {
      type: Boolean,
    },
    multi: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DgIcon,
    Avatar,
  },
  mounted() {
    window.addEventListener('click', this.methodDetectToClose);
  },
  data() {
    return {
      activeIndex: null,
      show: false,
      count: 0,
      filterText: '',
    };
  },
  computed: {
    listShow() {
      if (!this.filter) return this.list;
      return this.list.filter(({ label }) => {
        const reg = new RegExp(this.filterText, 'i');
        return reg.test(label);
      });
    },
    showMulitValue() {
      if (this.value && this.value.length > 0) return this.value?.map(({ label } = {}) => label).join(', ') ?? '';
      return '';
    },
  },
  watch: {
    list(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.$emit('selected', '');
      }
    },
    filterText(newVal, oldVal) {
      if (!this.multi && newVal !== oldVal) {
        if (this.value && this.value.label !== newVal) {
          this.$emit('selected', '');
        }
      }
    },
  },
  methods: {
    methodDetectToClose(v) {
      if (this.show && this.count > 0 && !v.path.find((e) => e === this.$refs.dgSelectButton)) {
        this.show = false;
        this.count = 0;
      }
      if (this.show) this.count += 1;
    },
    methodToggleShow() {
      this.show = !this.show;
    },
    methodClear() {
      this.$emit('clear');
    },
    methodItemIsSelected(item) {
      if (this.multi && this.value.length > 0) {
        return !!this.value?.find((i) => isEqual(i, item));
      }
      return isEqual(this.value, item);
    },
    methodUpdate(item) {
      this.filterText = item.label;
      if (this.multi) {
        const itemInSelectedIdx = this.value ? this.value.findIndex((i) => isEqual(i, item)) : -1;
        if (itemInSelectedIdx === -1) {
          this.$emit('selected',
            [
              ...this.value,
              item,
            ]);
        } else {
          this.$emit('selected',
            [
              ...this.value.slice(0, itemInSelectedIdx),
              ...this.value.slice(itemInSelectedIdx + 1),
            ]);
        }
      } else {
        this.$emit('selected', item);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('click', this.methodDetectToClose);
    this.show = false;
  },
};
</script>
