import Schema from 'async-validator';
import cn from './cn';

export default (rules) => {
  const validator = new Schema(rules);
  validator.messages(cn);

  return ((data) => {
    let result = null;
    function handleErrors(error, fields) {
      result = Object.keys(fields).reduce((acc, key) => {
        acc[key] = fields[key].map(({ message }) => message).join(', ');
        return acc;
      }, {});
    }
    validator.validate(data, { suppressWarning: true }, handleErrors);
    return result;
  });
};
