<template>
  <div
    class="relative h-10 flex items-center"
  >
    <div
      class="flex items-center pb-1"
      :class="layoutType"
    >
      <div
        v-for="(item, idx) in list"
        :key="`radio-item-${item}-${idx}`"
        class="flex items-center w-full"
        :class="layoutType === 'flex-col' ? 'py-2' : ''"
      >
        <label
          class="mr-7 block text-base text-ash-600 whitespace-nowrap"
        >
          <input
            type="radio"
            ref="dgRadio"
            class="text-primary border-gray-600 h-5 w-5 focus:ring-transparent mr-2"
            :value="item.value"
            v-model="currentValue"
            @change="methodUpdate"
          />
          {{item.label}}
        </label>
      </div>
    </div>
    <span
      v-if="errorMsg"
      class="text-xs text-primary absolute left-1 top-full transform translate-y-0.5 opacity-90"
    >{{errorMsg}}</span>
  </div>
</template>

<script>
import { isEqual } from '@/lib/lodash';

export default {
  name: 'DgRadio',
  props: {
    value: {
      type: [String, Number],
    },
    list: {
      type: Array,
    },
    errorMsg: {
      type: String,
      default: '',
    },
    layoutType: {
      type: String,
      default: 'flex-row',
      validator(v) {
        return ['flex-row', 'flex-col'].indexOf(v) !== -1;
      },
    },
  },
  components: {
  },
  data() {
    return {
      currentValue: '',
    };
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.currentValue = newVal;
        }
      },
      immediate: true,
    },
  },
  methods: {
    methodClear() {
      this.$emit('clear');
    },
    methodUpdate(v) {
      const result = v.target.value;
      this.$emit('selected', result);
    },
  },
};
</script>
