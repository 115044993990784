<template>
  <div
    class="dg-editor relative pb-1"
  >
    <div
      class="bg-white"
      id="wrapDgEditor"
    >
      <!-- @ready, @focus, @change -->
      <quill-editor
        ref="myQuillEditor"
        v-model="content"
        :options="options"
        @blur="methodGetContent"
      />
    </div>
    <span
      v-if="errorMsg"
      class="text-xs text-primary absolute left-1 top-full transform translate-y-0.5 opacity-90"
    >{{errorMsg}}</span>
    <div
      class="hidden fixed bottom-0 left-0 -z-1 opacity-0 invisible"
      :id="editorProcessId"
    ></div>
  </div>
</template>

<script>
import { isEqual } from '@/lib/lodash';

export default {
  name: 'DgEditor',
  props: {
    value: {
      type: String,
    },
    uniKey: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    errorMsg: {
      type: String,
      default: '',
    },
    minlength: {
      type: Number,
      default: 0,
    },
    maxlength: {
      type: Number,
    },
  },
  data() {
    return {
      quill: null,
      quillElm: null,

      content: '',
      options: {},
    };
  },
  computed: {
    editorProcessId() {
      return `dg-editor-process-${this.uniKey}`;
    },
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.content = this.value;
      }
    },
  },
  methods: {
    methodGetContent() {
      this.$emit('input', this.content);
    },
    // onEditorChange({ quill, html, text }) {
    //   this.content = html;
    // },
  },
};
</script>
