<template>
  <div
    class="select-wrap w-full h-2 rounded-full bg-ash-50 relative my-3"
  >
    <div
      class="absolute top-0 left-0 h-full bg-primary opacity-30 rounded-full w-0"
      :class="`select-bar-${uniKey}`"
    ></div>
      <!-- :style="{width: activeSlideWidth}" -->
    <div
      class="w-11 h-5 flex justify-center items-center bg-primary rounded-full
      absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2"
      :class="`select-item-${uniKey}`"
    >
      <!-- :style="{left: activeSlideWidth}" -->
      <DgIcon
        size="9"
        name="grip-lines-vertical"
        color="white"
      />
      <p
        class="text-base text-ash-600 absolute top-full select-none"
      :class="`select-text-${uniKey}`"
      >{{text}}</p>
    </div>
  </div>
</template>

<script>
import { debounce, isEqual } from '@/lib/lodash';
import DgIcon from '@/components/base/DgIcon.vue';

export default {
  name: 'DgSlider',
  props: {
    uniKey: {
      type: String,
    },
    value: {
      type: Number,
    },
    min: {
      type: [Number, String],
      default: 0,
    },
    max: {
      type: [Number, String],
      default: 100,
    },
    gap: {
      type: [Number, String],
      default: 10,
    },
  },
  components: {
    DgIcon,
  },
  data() {
    return {
      current: 0,
    };
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.current = newVal;
        }
      },
      immediate: true,
    },
  },
  computed: {
    count() {
      return (this.max - this.min) / this.gap + 1;
    },
    activeSlideWidth() {
      return `${(100 / this.count) * this.current}%`;
    },
    text() {
      return this.current * this.gap;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.methodInit();
    });
  },
  methods: {
    methodInit() {
      const wrapElm = document.querySelector('.select-wrap');
      const wrapWidth = wrapElm.clientWidth;

      const elm = document.querySelector(`.select-item-${this.uniKey}`);
      const barElm = document.querySelector(`.select-bar-${this.uniKey}`);
      const textElm = document.querySelector(`.select-text-${this.uniKey}`);
      const hammerInstance = new this.$hammer(elm, {
        cssProps: {
          touchAction: 'pan-y',
        },
      });

      hammerInstance.get('pan').set({ direction: this.$hammer.DIRECTION_HORIZONTAL });
      hammerInstance.on('panleft panright', (e) => {
        if (this.stop) return;
        const origin = (100 / this.count) * this.current;
        const ratio = (e.deltaX / wrapWidth) * 100;

        const res = origin + ratio;
        let result = res;
        if (res < 0) result = 0;
        if (res > 100) result = 100;
        elm.style.left = `${result}%`;
        barElm.style.width = `${result}%`;

        const tmp = elm?.style?.left?.replace('%', '')?.split('.') ?? [];
        const pos = tmp?.length > 0 ? tmp[0] : 0;
        textElm.textContent = Math.round((pos * this.max) / 100);
      });

      const func = (e) => {
        if (e.isFinal) {
          this.methodUpdateCurrent();
        }
      };
      const panEvet = debounce(func, 0, { leading: false });

      hammerInstance.on('pan', panEvet);
    },
    methodUpdateCurrent() {
      const elm = document.querySelector(`.select-item-${this.uniKey}`);
      const barElm = document.querySelector(`.select-bar-${this.uniKey}`);

      const tmp = elm?.style?.left?.replace('%', '')?.split('.') ?? [];
      const pos = tmp?.length > 0 ? tmp[0] : 0;
      this.current = Math.round((pos * this.max) / 100);
      elm.style.left = `${(100 / this.count) * this.current}%`;
      barElm.style.width = `${(100 / this.count) * this.current}%`;
      this.methodUpdate();
    },
    methodUpdate() {
      this.$emit('input', this.current);
    },
  },
};
</script>
