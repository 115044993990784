<template>
  <div
    class="relative"
  >
    <div
      type="text"
      ref="dgFileUpload"
      class="w-full rounded-md focus:border-primary focus:ring-transparent pr-28"
      :class="errorMsg ? 'border-primary border-opacity-90' : 'border-ash-100'"
    >
      <div
        class="flex lg:flex-row flex-col items-center"
      >
        <div>
          <div
            v-if="!value"
            class="w-40 bg-ash-50 flex justify-center items-center rounded-md"
            :style="{height: '90px'}"
          >
            <DgIcon
              size="37"
              name="placeholder"
            />
          </div>
          <DgImg
            v-else
            :src="value.image"
            placeholderSrc="imgs/imgItemSelectPlaceholder"
            :ratio="160/90"
            class="rounded-md w-full h-full"
            :style="{'min-width': isMobile ? '80px': '160px'}"
          />
        </div>
        <div
          class="flex flex-col justify-end pl-4"
        >
          <DgIcon
            v-if="value && (value.type !== undefined)"
            size="20"
            :name="value.type"
            color="primary-gradient-to-b"
            class="mb-2"
          />
          <p
            v-if="!value"
            class="text-base text-ash-200"
          >{{placeholder}}</p>
          <p
            v-else
            class="text-base text-ash-800"
          >{{value.title}}</p>
        </div>
      </div>
    </div>
    <div
      class="flex flex-col items-center px-3.5 absolute right-0 top-1/2 transform -translate-y-3"
    >
    <!-- flex absolute top-1/2 transform -translate-y-1/2 right-0  -->
      <div
        class=""
      >
        <button
          class="text-sm text-white bg-primary px-4 py-1 relative cursor-pointer rounded-full
          flex justify-center items-center"
          @click="methodOpenModal"
        >
        {{buttonLabel}}
        </button>
      </div>
      <div
        v-if="value"
        class="cursor-pointer mt-1"
        @click="methodClear"
      >
        <p
          class="text-sm text-ash-200 px-7 py-1"
        >移除</p>
      </div>
    </div>
    <span
      v-if="errorMsg"
      class="text-xs text-primary absolute left-1 top-full transform translate-y-0.5 opacity-90"
    >{{errorMsg}}</span>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import DgImg from '@/components/base/DgImg.vue';
import DgIcon from '@/components/base/DgIcon.vue';

export default {
  name: 'DgItemSelect',
  inject: ['device'],
  props: {
    value: {
      type: [Number, Object, String],
    },
    list: {
      type: Array,
    },
    uniKey: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    errorMsg: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
    },
    buttonLabel: {
      type: String,
      default: '請選擇',
    },
    modalComponent: {
      type: Object,
    },
  },
  components: {
    DgImg,
    DgIcon,
  },
  data() {
    return {
    };
  },
  computed: {
    isMobile() {
      return this.device.isMobile;
    },
    // fileName() {
    //   return this.value?.name ?? '';
    // },
  },
  methods: {
    ...mapMutations({
      mutationSetModal: 'setModal',
    }),
    methodOpenModal() {
      this.mutationSetModal({
        show: true,
        propsData: {
          value: this.value,
          list: this.list,
        },
        confirmCallback: this.methodUpdate,
        contentComponent: this.modalComponent,
      });
    },
    methodClear() {
      this.$emit('clear');
    },
    methodUpdate(v) {
      this.$emit('input', v);
    },
  },
};
</script>
